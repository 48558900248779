import './PageSectionPictureCollageBanner.scss';

import { graphql } from 'gatsby';
import React from 'react';

import { LinkTagConfig } from '../../../types/appContentConfig.types';
import { PictureCollageBannerSection } from '../../../types/wordpress.types';
import BaseButton from '../../BaseButton/BaseButton';
import BaseIcon from '../../BaseIcon/BaseIcon';
import BaseImage from '../../BaseImage/BaseImage';
import BaseLink from '../../BaseLink/BaseLink';
import PageSection from '../../PageSection/PageSection';
import { Renderable } from '../../../types/base.types';

export const PageSectionPictureCollageBannerFragment = graphql`
  fragment PageSectionPictureCollageBannerFragment on WpTemplate_FrontPage {
    pictureCollageBannerSection {
      pictureCollageBannerMessage
      pictureCollageBannerPictures {
        ...Image_MediaItem
      }
    }
  }
`;

type PageSectionPictureCollageBannerProps = {
  pictureCollageSection: PictureCollageBannerSection,
  linkButtonsConfig: LinkTagConfig[],
  Subheading: Renderable,
}

const PageSectionPictureCollageBanner: React.FC<PageSectionPictureCollageBannerProps> = props => {

  const p = props;
  const s = {
    get message() { return p.pictureCollageSection.pictureCollageBannerMessage },
    get pictures() { return p.pictureCollageSection.pictureCollageBannerPictures },
  }

  return <PageSection id="PageSectionPictureCollageBanner" className="PageSectionPictureCollageBanner page-section section-picture-collage-banner" observeVisibility>
    {s.pictures && <figure className="PageSectionPictureCollageBanner_Figure">
      {s.pictures.map((picture) => (
        <picture key={picture.id} className="PageSectionPictureCollageBanner_Picture">
          <BaseImage
            className="filler PageSectionPictureCollageBanner_Picture_Image"
            media={picture}
            imageType='gatsbyDynamic'
          />
        </picture>
      ))}
    </figure>}
    <figcaption>
      <div className="container PageSectionPictureCollageBanner_FigcaptionContent">
        <h2>{p.pictureCollageSection.pictureCollageBannerMessage}</h2>
        {p.Subheading && <div className="PageSectionPictureCollageBanner_Subheading">
          {p.Subheading}
        </div>}
        <div>
          {p.linkButtonsConfig.map((linkBtnConfig, idx) => (
            <BaseLink key={idx} to={linkBtnConfig.href}>
              <BaseButton className={linkBtnConfig.className} >
                {linkBtnConfig.icon && <BaseIcon icon={linkBtnConfig.icon} size={linkBtnConfig.iconSize} />}
                <span>{linkBtnConfig.Label}</span>
              </BaseButton>
            </BaseLink>
          ))}
        </div>
      </div>
    </figcaption>
  </PageSection>
}

export default PageSectionPictureCollageBanner;